<template>
  <component :is="'PageWithColoredCenteredBox'">
    <div class="page page-checklist">
      <h2 class="h-2">{{ $t('page-checklist.title') }}</h2>
      <div class="checklist-container margin-top-m">
        <div class="check-container">
          <input type="checkbox" id="check1" value="check1" v-model="this.checked"/>
          <label for="check1">{{ $t('page-checklist.checkbox-1') }}</label>
        </div>
        <div class="check-container">
          <input type="checkbox" id="check2" value="check2" v-model="this.checked"/>
          <label for="check2">{{ $t('page-checklist.checkbox-2') }}</label>
        </div>
        <div class="check-container">
          <input type="checkbox" id="check3" value="check3" v-model="this.checked"/>
          <label for="check3">{{ $t('page-checklist.checkbox-3') }}</label>
        </div>
        <div class="check-container">
          <input type="checkbox" id="check4" value="check4" v-model="this.checked"/>
          <label for="check4">{{ $t('page-checklist.checkbox-4') }}</label>
        </div>
        <div class="check-container">
          <input type="checkbox" id="check5" value="check5" v-model="this.checked"/>
          <label for="check5">{{ $t('page-checklist.checkbox-5') }}</label>
        </div>
        <div class="check-container">
          <input type="checkbox" id="check6" value="check6" v-model="this.checked"/>
          <label for="check6">{{
              $t('page-checklist.checkbox-6', {
                       distance: getDistance() === 0 ? $t('global.distance-40-cm') : $t('global.distance-2-m'),
                       }
              )
            }}</label>
        </div>
      </div>

      <div class="actions">
        <button class="btn primary-bg small-btn margin-top-l" v-on:click="this.startTest()" :disabled="!allChecked">
          {{ $t('page-checklist.start-test-button') }}
        </button>
      </div>
    </div>
  </component>
</template>

<script>
import PageWithColoredCenteredBox from "@/components/pages/generic/PageWithColoredCenteredBox";

export default {
  components: {PageWithColoredCenteredBox},
  data() {
    return {
      checked: [],
    };
  },
  methods: {
    startTest() {
      this.$store.commit('startTimer');
      this.$store.commit('setChecklist', this.checked);
      this.$changePageWithAnalyticsStep(this.$store, 'PageHowItWorks');
    },
    getDistance() {
      return this.$store.state.distance;
    }
  },
  computed: {
    allChecked() {
      return this.checked.length === 6;
    },
  },
};
</script>
